import { IAnnouncementDraft } from '@creatorhub/shared/creators';
import { handleHttpClientError } from '@creatorhub/shared/util';
import { Suspense, useEffect, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AnalyticEvent, analytics } from '../analytics';
import { AnnouncementForm } from '../components/Announcements/AnnouncementForm';
import { Header } from '../components/Header';
import { Loader } from '../components/Loader';
import { useAuth } from '../contexts/Auth';
import { useCreateAnnouncementDraftMutation } from '../hooks/useAnnouncementDrafts';
import { useCollections } from '../hooks/useCollection';
import { evaluateFlag, FeatureFlagKeys } from '@creatorhub/shared';

export const AnnouncementCreatePage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  // Bitcoin announcements not supported. Remove when support is ready.
  const [excludeBtcAnnouncements, setExcludeBtcAnnouncements] = useState(false);
  useEffect(() => {
    setExcludeBtcAnnouncements(
      (evaluateFlag({
        key: FeatureFlagKeys.excludeBtcAnnouncements,
      }) as any) === 'on',
    );
  }, [excludeBtcAnnouncements]);

  const collectionsQuery = useCollections(
    excludeBtcAnnouncements ? 'bitcoin' : undefined,
  );
  const createMutation = useCreateAnnouncementDraftMutation();
  const formInitialValues: Partial<IAnnouncementDraft> = {};

  return (
    <div className="page">
      <Suspense fallback={<Loader />}>
        <Header />
        <div className="profile-page container w-[680px]">
          <div className="flex items-center justify-between mb-9">
            <h1 className="text-3xl font-extrabold">
              Create a new announcement
            </h1>

            <FiX
              size="32px"
              className="cursor-pointer hover:opacity-70"
              onClick={() => navigate('/dashboard?page=announcements')}
            />
          </div>
          <AnnouncementForm
            initialValues={formInitialValues}
            collections={collectionsQuery.data ?? []}
            publishAtminDate={new Date()}
            onSubmit={values => {
              createMutation.mutate(values, {
                onSuccess: resPayload => {
                  const selectedCollectionDraft = (
                    collectionsQuery.data ?? []
                  ).find(
                    collectionDraft =>
                      collectionDraft._id === values.collectionDraftId,
                  );
                  if (values.submittedAt != null) {
                    analytics.trackAggregate({
                      eventName: AnalyticEvent.CREATOR_ANNOUNCEMENT_SUBMIT,
                      announcement_draft_id: resPayload._id,
                      collection_symbol: selectedCollectionDraft?.symbol,
                      email: user?.email,
                    });
                  } else {
                    analytics.trackAggregate({
                      eventName: AnalyticEvent.CREATOR_ANNOUNCEMENT_DRAFT_SAVED,
                      announcement_draft_id: resPayload._id,
                      collection_symbol: selectedCollectionDraft?.symbol,
                      email: user?.email,
                    });
                  }
                  const tab = values.submittedAt != null ? 'pending' : 'draft';
                  navigate(`/dashboard?page=announcements&tab=${tab}`);
                },
                onError: err => {
                  const [_status, _errCode, _errMsg, resMsg] =
                    handleHttpClientError(err);
                  toast.error(
                    resMsg ||
                      'something went wrong, try again in couple of minutes',
                  );
                },
              });
            }}
          />
        </div>
      </Suspense>
    </div>
  );
};
