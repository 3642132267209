import { isValidSignature } from '../util';
/**
 * ownership transfer process submission status
 */
export var OwnershipTransferSubmissionStatus;
(function (OwnershipTransferSubmissionStatus) {
    /* user collecting proofs */
    OwnershipTransferSubmissionStatus["PENDING"] = "pending";
    /* user collected all proof, ME admin must approve or reject */
    OwnershipTransferSubmissionStatus["REVIEW"] = "review";
    /* ME admin approved, transfer of ownership completed */
    OwnershipTransferSubmissionStatus["APPROVED"] = "approved";
    /* ME admin rejected, transfer of ownership canceled */
    OwnershipTransferSubmissionStatus["REJECTED"] = "rejected";
})(OwnershipTransferSubmissionStatus || (OwnershipTransferSubmissionStatus = {}));
export const PROOF_TYPES = Object.freeze([
    'ua', // only Update Authority for now
]);
export function isAllProofsVerified(obj) {
    return obj.proof.every(proof => {
        if (proof.proofType === 'ua') {
            return proof.verificationSig && isValidSignature(proof.verificationSig);
        }
        return false;
    });
}
