import { lazy, useEffect, useRef } from 'react';

import { FeatureFlagVariants } from '@creatorhub/shared/services';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { analytics } from './analytics';
import { Logout } from './components/Logout';
import { AdminRoute, UserRoute } from './components/UserRoute';
import { useAuth } from './contexts/Auth';
import { useFeatureFlags } from './hooks/useFeatureFlagEnabled';
import AdminActivityMonitorMCC from './pages/Admin/AdminActivityMonitorMCC';
import AdminLaunchpadDrafts from './pages/Admin/AdminLaunchpadDrafts';
import { AnnouncementCreatePage } from './pages/AnnouncementCreatePage';
import { AnnouncementEditPage } from './pages/AnnouncementEditPage';
const SignIn = lazy(() => import('./pages/SignIn'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const LaunchpadEditor = lazy(() => import('./pages/LaunchpadEditor'));
const ListingEditor = lazy(() => import('./pages/ListingEditor'));
const NewDraft = lazy(() => import('./pages/Editor/NewDraft'));
const CollectionClaim = lazy(
  () => import('./pages/CollectionDraftClaim/ClaimPage'),
);
const CollectionClaimVerify = lazy(
  () => import('./pages/CollectionDraftClaim/VerifyPage'),
);
const AdminBlacklist = lazy(() => import('./pages/Admin/AdminBlacklist'));
const AdminUpdateDraft = lazy(() => import('./pages/Admin/AdminUpdateDraft'));
const AdminCollectionDrafts = lazy(
  () => import('./pages/Admin/AdminCollectionDrafts'),
);
const AdminVerificationHub = lazy(
  () => import('./pages/Admin/AdminVerificationHub'),
);
const Verify = lazy(() => import('./pages/Verify'));

export const AppRoutes = () => {
  const { features } = useFeatureFlags();

  const { user } = useAuth();
  const prevPath = useRef<string | null>(null);
  const location = useLocation();
  useEffect(() => {
    const fullPath = location.pathname + location.search;
    // only log page if user is logged in
    // rationale: if we also log when user is not logged in then we cannot distinguish the initial redirection
    // when user is on dashboard (not logged in) -> dashboard (auto login)
    // if we detect user email switch from undefined -> defined, then we will log the dashboard (not logged in) -> logged in
    // everytime, which is not useful again. We will think of another strategy to log when we need explicit login
    if (fullPath !== prevPath.current && user?.email) {
      prevPath.current = fullPath;
      analytics.page({
        properties: {
          email: user?.email,
        },
      });
    }
  }, [location.pathname, location.search, user?.email]);

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/signin" element={<SignIn />} />

      {/* legacy admin, prefer to build in admin-fe package */}

      <Route
        path="/admin"
        element={<AdminRoute component={AdminCollectionDrafts} />}
      />
      <Route
        path="/admin/collection-drafts"
        element={<AdminRoute component={AdminCollectionDrafts} />}
      />

      <Route
        path="/admin/blacklist"
        element={<AdminRoute component={AdminBlacklist} />}
      />

      <Route
        path="/admin/update"
        element={<AdminRoute component={AdminUpdateDraft} />}
      />

      <Route
        path="/admin/launchpads"
        element={<AdminRoute component={AdminLaunchpadDrafts} />}
      />

      <Route
        path="/admin/activity-monitor-mcc"
        element={<AdminRoute component={AdminActivityMonitorMCC} />}
      />

      <Route
        path="/admin/verification-hub"
        element={<AdminRoute component={AdminVerificationHub} />}
      />

      {/* end of legacy admin */}

      {/* Creators Dashboard */}
      <Route path="/dashboard" element={<UserRoute component={Dashboard} />} />

      {/* Launchpad */}
      <Route
        path="/launchpad/draft/new/*"
        element={<UserRoute component={NewDraft} />}
      />
      <Route
        path="/launchpad/draft/:id/*"
        element={<UserRoute component={LaunchpadEditor} />}
      />

      {/* collection listing application */}
      <Route
        path="/dashboard/listing/:id/*"
        element={<UserRoute component={ListingEditor} />}
      />

      {features.k955g2yv3goc9ecz6 === FeatureFlagVariants.On && (
        <Route
          path="/dashboard/claim"
          element={<UserRoute component={CollectionClaim} />}
        />
      )}
      {features.k955g2yv3goc9ecz6 === FeatureFlagVariants.On && (
        <Route
          path="/dashboard/claim/:id/verify"
          element={<UserRoute component={CollectionClaimVerify} />}
        />
      )}

      {/* creator announcements */}
      <Route
        path="/dashboard/announcements/new/*"
        element={<UserRoute component={AnnouncementCreatePage} />}
      />
      <Route
        path="/dashboard/announcements/:announcementId/*"
        element={<UserRoute component={AnnouncementEditPage} />}
      />

      {/* Utility pages  */}
      <Route path="/verify" element={<Verify />} />

      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
};
