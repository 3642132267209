import './polyfill';
import './rum';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { Toaster } from 'react-hot-toast';
import { cluster } from './config';
import { ClusterProvider } from './contexts/Cluster';
import WalletProvider from './contexts/Wallet';
import { AppRoutes } from './routes';

import './style.scss';
import { BrowserRouter } from 'react-router-dom';
import { Suspense } from 'react';
import { Loader } from './components/Loader';
import { AuthProvider } from './contexts/Auth';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ClusterProvider initial={cluster}>
      <QueryClientProvider client={queryClient}>
        <WalletProvider>
          <AuthProvider>
            <Suspense fallback={<Loader />}>
              <Toaster />
              <AppRoutes />
            </Suspense>
          </AuthProvider>
        </WalletProvider>
      </QueryClientProvider>
    </ClusterProvider>
  </BrowserRouter>,
);
