export var SubmissionStatus;
(function (SubmissionStatus) {
    SubmissionStatus["PENDING"] = "pending";
    SubmissionStatus["REVIEW"] = "review";
    SubmissionStatus["APPROVED"] = "approved";
    SubmissionStatus["REJECTED"] = "rejected";
    /* @deprecated use CollecitonDraft listed property */
    SubmissionStatus["LISTED"] = "listed";
    SubmissionStatus["UPDATED"] = "updateFromApproved";
})(SubmissionStatus || (SubmissionStatus = {}));
/** This needs to be in sync with js/packages/services/src/typings/categories.ts in magiceden repo. */
export var CollectionCategories;
(function (CollectionCategories) {
    CollectionCategories["PFPS"] = "pfps";
    CollectionCategories["GAMES"] = "games";
    CollectionCategories["ART"] = "art";
    CollectionCategories["VIRTUAL_WORLDS"] = "virtual_worlds";
    CollectionCategories["MUSIC"] = "music";
    CollectionCategories["PHOTOGRAPHY"] = "photography";
    CollectionCategories["SPORTS"] = "sports";
})(CollectionCategories || (CollectionCategories = {}));
export var Currency;
(function (Currency) {
    Currency["SOL"] = "SOL";
    Currency["ETH"] = "ETH";
    Currency["MATIC"] = "MATIC";
    Currency["BTC"] = "BTC";
    Currency["UNKNOWN"] = "UNKNOWN";
})(Currency || (Currency = {}));
export const ChainCurrencyMap = {
    solana: Currency.SOL,
    ethereum: Currency.ETH,
    polygon: Currency.MATIC,
    bitcoin: Currency.BTC,
};
