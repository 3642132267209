import axios from 'axios';
import { getMEURL } from '../services';
export async function getNFTsByHashList(hashList, includeBurntMint) {
    const fetches = hashList.map((hash) => {
        return axios
            .get(`${getMEURL()}/rpc/getNFTByMintAddress/${hash}?includeBurntMint=${includeBurntMint}`)
            .then(res => res.data);
    });
    const values = await Promise.allSettled(fetches);
    return values
        .map((value) => (value.status === 'fulfilled' ? value.value.results : undefined))
        .filter((value) => value !== null && value !== undefined);
}
