import { handleHttpClientError } from '@creatorhub/shared/util';
import {
  isRejected,
  isInReviewPipeline,
  REVIEW_REQUEST_DEFAULT_REJECT_MESSAGE,
} from '@creatorhub/shared/admin';
import { Suspense } from 'react';
import { FiX } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AnnouncementForm } from '../components/Announcements/AnnouncementForm';
import { Header } from '../components/Header';
import { Loader } from '../components/Loader';
import {
  useAnnouncementDraftQuery,
  useUpdateAnnouncementDraftMutation,
} from '../hooks/useAnnouncementDrafts';
import { useCollections } from '../hooks/useCollection';
import { AnalyticEvent, analytics } from '../analytics';
import { useAuth } from '../contexts/Auth';

export const AnnouncementEditPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const pathParams = useParams<{ announcementId: string }>();
  const collectionsQuery = useCollections();
  const announcementDraftQuery = useAnnouncementDraftQuery(
    pathParams.announcementId || '',
  );
  const updateMutation = useUpdateAnnouncementDraftMutation(
    pathParams.announcementId || '',
  );

  if (!announcementDraftQuery.data) {
    return <Loader />;
  }

  return (
    <div className="page">
      <Suspense fallback={<Loader />}>
        <Header />
        <div className="profile-page container w-[680px]">
          <div className="flex items-center justify-between mb-9">
            <h1 className="text-3xl font-extrabold">Edit announcement</h1>

            <FiX
              size="32px"
              className="cursor-pointer hover:opacity-70"
              onClick={() => navigate('/dashboard?page=announcements')}
            />
          </div>
          {isInReviewPipeline(announcementDraftQuery.data) && (
            <h2 className="text-xl mb-8 text-[#ffaa00]">
              You cannot edit this announcement because it is in review
            </h2>
          )}
          {isRejected(announcementDraftQuery.data) && (
            <h2 className="text-xl mb-8 text-[#ffaa00]">
              Your latest changes were rejected.{' '}
              {announcementDraftQuery.data?.reviewRequest?.message ||
                REVIEW_REQUEST_DEFAULT_REJECT_MESSAGE}
            </h2>
          )}
          <AnnouncementForm
            disabled={isInReviewPipeline(announcementDraftQuery.data)}
            initialValues={announcementDraftQuery.data}
            collections={collectionsQuery.data ?? []}
            onSubmit={values => {
              updateMutation.mutate(values, {
                onSuccess: resPayload => {
                  const selectedCollectionDraft = (
                    collectionsQuery.data ?? []
                  ).find(
                    collectionDraft =>
                      collectionDraft._id === values.collectionDraftId,
                  );
                  analytics.trackAggregate({
                    eventName: AnalyticEvent.CREATOR_ANNOUNCEMENT_REVISE,
                    announcement_draft_id: resPayload._id,
                    collection_symbol: selectedCollectionDraft?.symbol,
                    email: user?.email,
                  });

                  const tab = values.submittedAt != null ? 'pending' : 'draft';
                  navigate(`/dashboard?page=announcements&tab=${tab}`);
                },
                onError: err => {
                  const [_status, _errCode, _errMsg, resMsg] =
                    handleHttpClientError(err);
                  toast.error(
                    resMsg ||
                      'something went wrong, try again in couple of minutes',
                  );
                },
              });
            }}
          />
        </div>
      </Suspense>
    </div>
  );
};
