import { Field, Form, Formik } from 'formik';
import { Input } from '../Input/Input';
import InputHint from '../Input/InputHint';
import { Textarea } from '../Input/Textarea';
import { DatePicker } from '../Forms/DatePicker';
import { Button } from '../Button/Button';
import {
  DEFAUL_LIVE_DAYS,
  MAX_CONTENT_CHARS,
  MAX_TITLE_CHARS,
  IAnnouncementDraft,
  ICollectionDraft,
} from '@creatorhub/shared/creators';
import { ErrorHint } from '../Input/ErrorHint';
import * as yup from 'yup';
import { CollectionSelection } from '../CollectionSelection';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';

type AnnouncementFormProps = {
  initialValues: Partial<IAnnouncementDraft>;
  onSubmit: (values: Partial<IAnnouncementDraft>) => void;
  // available collections to announcements for
  collections: ICollectionDraft[];
  disabled?: boolean;
  // require a publish at min date
  publishAtminDate?: Date;
};

const AnnouncementDraftSchema = yup.object().shape({
  collectionDraftId: yup.string().required('select a collection'),
  title: yup.string().required('headline is required').max(MAX_TITLE_CHARS),
  content: yup
    .string()
    .required('message body is required')
    .max(MAX_CONTENT_CHARS),
  publishAt: yup
    .date()
    .typeError('you must have a go live date')
    .min(new Date(), params => `go live date must be in the future`),
});

export const AnnouncementForm = (props: AnnouncementFormProps) => {
  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={AnnouncementDraftSchema}
      validateOnBlur
      onSubmit={props.onSubmit}
    >
      {formProps => (
        <Form>
          <h2 className="text-2xl font-extrabold mb-4">Select Collection</h2>
          <CollectionSelection
            placeholder="Select a collection for this announcement"
            isOptionDisabled={option => option.label.listed !== true}
            selectedValue={formProps.values.collectionDraftId}
            options={props.collections.map(item => ({
              label: item,
              value: item._id,
              disabledMessage:
                'list this collection on magiceden.io to add announcements',
            }))}
            onChange={newValue => {
              if (newValue) {
                formProps.setFieldValue('collectionDraftId', newValue.value);
              }
            }}
          />
          <ErrorHint>{formProps.errors.collectionDraftId}</ErrorHint>
          <h2 className="text-2xl font-extrabold mt-12 mb-4">Headline</h2>
          <Field
            as={Input}
            type="text"
            name="title"
            placeholder="Type headline here"
            disabled={props.disabled}
          />
          <ErrorHint>{formProps.errors.title}</ErrorHint>
          <h2 className="text-xl font-extrabold mt-12 mb-4">Message body</h2>
          <Field
            as={Textarea}
            name="content"
            placeholder="Type the body of your message here"
            disabled={props.disabled}
          />
          <ErrorHint>{formProps.errors.content}</ErrorHint>
          <Field name="publishAt">
            {({ form, field }: any) => {
              return (
                <div className="mt-12 mb-12">
                  <div>
                    <h2 className="text-xl font-extrabold mb-4">
                      Go live date & time
                    </h2>
                    <div className="flex items-start gap-2">
                      <div className="w-1/2">
                        <DatePicker
                          className="mb-0"
                          field="publishAt"
                          hasError={form.errors.publishAt}
                          placeholder="Pick a go live date and time"
                          value={{
                            publishAt: field.value
                              ? new Date(field.value).toISOString()
                              : '',
                          }}
                          options={{
                            minDate: props.publishAtminDate,
                            format: 'MMMM d, yyyy h:mm aa zz',
                            publishAt: new Date(),
                          }}
                          onChange={(val: any) => {
                            form.setFieldValue('publishAt', val);
                          }}
                        />
                        {field.value && (
                          <InputHint
                            text={`Announcement will expire from the collection page and be moved to announcements tab after ${format(
                              addDays(new Date(field.value), DEFAUL_LIVE_DAYS),
                              'MMMM d, yyyy h:mm aa zz',
                            )}`}
                          />
                        )}
                      </div>
                      {form.values.publishAt && (
                        <Button
                          className="flex-shrink h-10"
                          variant="transparent"
                          type="button"
                          onClick={() =>
                            formProps.setFieldValue('publishAt', null)
                          }
                        >
                          Clear
                        </Button>
                      )}
                    </div>
                  </div>
                  {form.errors?.publishAt && (
                    <ErrorHint>{form.errors.publishAt}</ErrorHint>
                  )}
                </div>
              );
            }}
          </Field>
          <div className="flex justify-end gap-4">
            {props.initialValues.submittedAt == null && (
              <Button
                variant="secondary"
                type="submit"
                onClick={() =>
                  formProps.setFieldValue('submittedAt', undefined)
                }
                disabled={props.disabled}
              >
                Save as Draft
              </Button>
            )}
            <Button
              variant="primary"
              type="submit"
              onClick={() => formProps.setFieldValue('submittedAt', new Date())}
              disabled={props.disabled}
            >
              Submit for Review
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
