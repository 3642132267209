export var WhitelistTypes;
(function (WhitelistTypes) {
    WhitelistTypes["MAIN"] = "main";
    WhitelistTypes["GROUP"] = "group";
    WhitelistTypes["RAFFLE"] = "raffle";
})(WhitelistTypes || (WhitelistTypes = {}));
export var WhitelistStage;
(function (WhitelistStage) {
    WhitelistStage["REGISTER"] = "register";
    WhitelistStage["WINNERS_PICKED"] = "winnersPicked";
    WhitelistStage["FINAL"] = "final";
})(WhitelistStage || (WhitelistStage = {}));
